.steps-container {
  padding-bottom: 32px; // this is required to keep the bounding box of the entire steps element accurate and to account for the absolutely positioned labels (.step-label)
}

.steps {
  position: relative;
  display: flex;
  justify-content: space-between;

  & .progress-container {
    position: absolute;
    top: 14.5px;
    margin-top: -1.5px;
    left: 8px;
    right: 8px;
    height: 3px;
    background: $border-light;

    & .progress {
      position: absolute;
      transition: max-width 1000ms;
      width: 100%;
      max-width: 0;
      top: 0;
      left: 0;
      height: 100%;
      background: $border-brand;
    }
  }
}
.step {
  position: relative;
  & .step-label {
    position: absolute;
    white-space: nowrap;
    top: calc(29px + 8px);
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
  & .marker-container {
    width: 29px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;

    & .marker {
      border-radius: 100%;

      &.checked {
        width: 100%;
        height: 100%;
        color: $text-white;
        background: $background-brand;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 5px;

        svg {
          width: 14px;
          height: 14px;
        }
      }

      &.active,
      &.disabled {
        border: 3px solid transparent;
        width: 19px;
        height: 19px;
        transition: border-color 200ms;
        background: $background-white;
      }

      &.active {
        border-color: $border-brand;
      }

      &.disabled {
        border-color: $border-light;
      }
    }
  }
}
