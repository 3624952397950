.box {
  margin: 0;
  padding: 0;
  background: $background-white;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  flex-grow: 1;
  position: relative;
  border: 1px solid transparent;

  &.is-fullheight {
    height: 100%;
  }

  & .box-title {
    padding: 16px 32px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border-lighter;
    position: sticky;
    top: 0;
    background: $background-white;
    z-index: 1;
  }

  & .box-title-relative {
    position: relative;
  }

  & .box-icons {
    padding: 32px 32px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: row;

    .left {
      display: flex;
      align-items: center;
    }

    .right {
      display: flex;
      margin-left: auto;
    }
  }
}

.box.is-disabled {
  opacity: 0.5;
}

.box.is-clickable {
  &:hover {
    cursor: pointer;
  }
}

.box.is-active {
  border: 1px solid $border-brand;
}

.box.is-collapsed {
  border: none;
}

.divider {
  background: $background-light;
  width: 100%;
  height: 1px;
}

.placeholder {
  border-radius: 5px;
  color: $brand;
  text-align: center;
  width: 100%;
  //height:100%;
  box-sizing: border-box;
  border: 1px solid $brand;
}

.export-box {
  border-radius: 8px;
  border: 1px solid #48b2c5;
  margin: 10px 0;
  padding: 16px;
  display: flex;

  &.new {
    border-color: grey;
    //align: center;
    cursor: pointer;
  }
  .content {
    border-left: 1px solid #48b2c5;
    margin-left: 16px;
    padding-left: 16px;
    display: flex;
    align-items: center;
  }
}
