.timeline {
  width: 100%;

  .timeline-label {
    width: auto;
    background: $background-brand-light;
    border-radius: 100px;
    border: 2px solid $border-brand;
    line-height: 25px;
    padding: 0 24px;
    color: $text-brand;
    font-weight: 600;
  }

  .top,
  .bottom {
    display: flex;
    justify-content: center;
  }

  .items {
    width: 100%;
    position: relative;
    flex-direction: column;
    padding: 32px 0;

    .timeline-bar {
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -1px;
      width: 2px;
      height: 100%;
      background: $background-brand;
    }

    .timeline-item {
      position: relative;
      width: 50%;
      padding: 0 16px 32px;

      &:last-child {
        padding: 0 16px;
      }

      &:before {
        position: absolute;
        display: block;
        top: 2px;
        content: "";
        width: 16px;
        height: 16px;
        background: $background-white;
        border-radius: 100%;
        border: 2px solid $border-brand;
      }

      &:nth-child(even) {
        margin-left: 50%;

        &:before {
          left: -8px;
        }
      }

      &:nth-child(odd) {
        text-align: right;
        &:before {
          right: -8px;
        }
      }
    }
  }
}
