.badge.correction {
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 177.04%;

  padding: 0 8px;
  border-radius: 3px;

  text-transform: uppercase;
  background: #FFEAEA;
  color: #DA6F6F;
  display: inline-block;
}