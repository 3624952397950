@charset "utf-8";

// C O L O R S

// Brand
$brand: #49b2c5;
$brand-light: #bfe8fe;
$brand-dark: #2c6b76;
$brand-grey: #bbbdbf;

// Typography
$text: #232323;
$text-darker: #646464;
$text-dark: #8e8e8e;
$text-light: #d0d0d0;
$text-white: #ffffff;
$text-brand: $brand;

// Icons
$icon-primary: #c4c4c4;
$icon-primary-hover: #666666;

$icon-secondary: $brand;
$icon-secondary-hover: $brand-dark;

// Status
$status-green: #3db39e;
$status-green-light: #d8f0ec;

$status-purple: #9754cc;
$status-purple-light: #eaddf5;

$status-orange: #eab01d;
$status-orange-light: #fbefd2;

$status-red: #e0215a;
$status-red-light: #f9d3de;

// Accents
$accent-primary: $brand;
$accent-primary-hover: $brand-dark;

$accent-secondary: #dbf0f3;
$accent-secondary-hover: #c8e8ee;

$accent-tertiary: #ffffff;
$accent-tertiary-hover: #c8e8ee;

// Borders
$border-lighter: #e9e9e9;
$border-light: #c4c4c4;
$border-grey: #979797;
$border-dark: #767676;
$border-darker: #636363;

$border-brand: $brand;

$border-error: #e0215a;

// Backgrounds
$background-white: #ffffff;

$background-lightest: #fcfcfc;
$background-lighter: #f5f5f5;
$background-light: #e9e9e9;

$background-brand-light: #dbf0f3;
$background-brand: $brand;

$background-modal-dark: rgba(0, 0, 0, 0.7); // TODO: Maybe change to hex

// TODO: generate color helper classes from refactored colors
.has-text-brand {
  color: $brand !important;
}

.has-text-white {
  color: $background-white !important;
}

.has-text-text-light {
  color: $text-light !important;
}

.has-text-text-dark {
  color: $text-dark !important;
}

.has-text-text-darker {
  color: $text-darker !important;
}

.has-text-status-green {
  color: $status-green !important;
}
.has-text-status-green-light {
  color: $status-green-light !important;
}

.has-text-status-purple {
  color: $status-purple !important;
}
.has-text-status-purple-light {
  color: $status-purple-light !important;
}

.has-text-status-orange {
  color: $status-orange !important;
}
.has-text-status-orange-light {
  color: $status-orange-light !important;
}

.has-text-status-red {
  color: $status-red !important;
}
.has-text-status-red-light {
  color: $status-red-light !important;
}
.has-text-status-blue {
  color: $brand !important;
}
