.matching-navigation {
  top: 0;
  left: 0;
  background: #fff;
  height: 110px;
  z-index: 100;
  width: 100%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
}

.matching-navigation-sticky {
  padding: 16px 32px;
  height: 110px;
}

.matching-stats {
  width: 588px;
  height: 24px;
  position: relative;
  border: 1px solid #000;
  margin-left: 16px;

  & .fraction {
    position: relative;
    height: 100%;
    left: 0;
    display: inline-block;
  }
}
.matching-pagination {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 16px 32px 16px 16px;
  width: 100vw;
  background: #fff;
  z-index: 100;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
}

.candidates-table {
  background: #fff;
  font-family: monospace;

  thead > tr > th {
    line-height: 2em;
    white-space: nowrap;
    position: sticky;
    top: 0px;
    background: #fff;
    z-index: 11;
    border-right: 1px solid #ccc;
    padding: 0 8px;
  }
}

.table {
  thead > tr > th {
    position: sticky;
    top: 0px;
    z-index: 11;
    background: #fff;
  }
}

.spacer-row {
  height: 48px;
}

.matching-row {
  height: 32px;

  td {
    border-right: 1px solid #ccc;
    padding: 0 8px;
  }

  &:hover .highlight {
    background: yellow;
  }

  &.matched td {
    background: #4fbf8e;
  }

  &.matched.third-party td {
    background: #95d9bb;
  }

  &.likely td {
    background: #f0eb33;
    &:hover .highlight {
      background: orange;
    }
  }

  &.likely.third-party td {
    background: #f9f7ad;
  }

  &.none td {
    background: #fff;
  }

  & .actions {
    min-width: 80px;
    background: rgba(255, 255, 255, 0.8);
    position: sticky;
    z-index: 11;
    padding-right: 16px;
    left: 0;
  }
}

.original {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;

  td {
    position: sticky;
    top: 30px;
    //max-width: 600px;
    //white-space: pre-wrap;
    white-space: nowrap;
  }

  .highlight {
    background: yellow;
  }

  &.likely {
    .highlight {
      background: orange;
    }
  }
}

.candidate {
  border-top: 1px solid #fff;
  font-weight: bold;
  cursor: pointer;

  &:hover td {
    background: rgb(211, 249, 246) !important;
  }
}

.empty-row {
  background-color: #f4f4f4;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23c4c3c5' fill-opacity='0.24' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}

.matching-navigation-top {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
}

.matching-navigation-bottom {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.matching-navigation-row {
  display: flex;
  align-items: center;

  & > div:first-of-type {
    margin-right: 16px;
  }
}
