$navbar-height: 68px;

.navbar {
  height: $navbar-height;
  background: $background-brand;
  //box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.03);
  color: $text-white;

  &.workbench {
    background: $text-white;
    color: $text-brand;

    .center > a {
      color: $text-brand;
    }

    .workbench-text {
      color: $text-brand;
      font-weight: bold;
      font-style: normal;
      font-size: 12px;
      position: absolute;
      top: 40px;
      left: 100px;
    }
  }

  &.staging {
    background: #7149c5;
  }

  &.local {
    background: mediumseagreen;
  }

  &.local,
  &.staging {
    .center > a {
      color: #fff;
    }
    &:after {
      display: block;
      content: attr(data-env);
      position: fixed;
      right: 0;
      top: 0;
      height: 68px;
      line-height: 68px;
      background: inherit;
      padding: 0 24px;
      font-weight: bold;
      border-radius: 0 0 10px 10px;
    }
  }
}

.sub-navbar {
  height: 50px;
  box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.03);
  background: $background-white;
}

.sub-navbar-placeholder {
  height: 50px;
  width: 100%;
}

.navbar,
.sub-navbar {
  width: 100%;

  .container {
    display: flex;
    flex-direction: row;
  }

  .left {
    display: flex;
    height: $navbar-height;
    align-items: center;
    width: 169px;
    margin-right: 88px;
  }

  .center {
    flex-grow: 1;
  }

  .right {
    display: flex;
  }
}

// main links
.navbar .center {
  & > a {
    color: $text-white;
    text-decoration: none;
    font-weight: normal;
    font-size: 16px;
    line-height: $navbar-height;
    display: inline-block;
    position: relative;
  }
  a:not(:first-child) {
    margin-left: 50px;
  }
  a.active {
    font-weight: bold;
  }
}

// active link indicator:
.navbar .center a.active:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  margin-left: -7px;
  bottom: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid $background-lighter;
}

.navbar.has-subnav .center a.active:after {
  border-bottom: 7px solid $background-white;
}

// sub navigation links
.sub-navbar .center {
  & > a {
    color: $text-brand;
    text-decoration: none;
    font-weight: normal;
    font-size: 16px;
    line-height: 50px;
    display: inline-block;
    position: relative;
  }
  a:not(:first-child) {
    margin-left: 50px;
  }
  a.active {
    font-weight: bold;
  }

  a.active:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    background: $background-brand;
  }
}

// branding
.branding-logo {
  display: block;
  width: 41px;
  height: 30px;
  cursor: pointer;

  & img {
    width: 41px;
    height: 30px;
  }
}

.branding-text {
  cursor: pointer;
  color: $text-white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
}

.navbar-item {
  display: flex;
  height: $navbar-height;
  align-items: center;
  & > .text {
    color: $text-white;
  }
  & > .icon {
    margin-right: 16px;
  }

  &.search {
    padding: 0 32px;
  }
}

.navbar-divider {
  height: $navbar-height;
  width: 1px;
  opacity: 0.5;
  //margin-left:32px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    height: 16px;
    width: 1px;
    top: 50%;
    margin-top: -8px;
    background: $background-white;
  }
}

// user and drop down menu

.user {
  padding-left: 32px;
  height: $navbar-height;
  position: relative;
  overflow: hidden;

  &:hover {
    overflow: initial;
  }

  .user-summary {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: $navbar-height;
    .text {
      color: $text-white;
    }
    .avatar {
      width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 100%;
      display: inline-block;
      margin-left: 16px;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  .user-menu {
    background: $background-white;
    width: 318px;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.07);
    position: absolute;
    top: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    z-index: 20;

    .divider {
      background: rgba(26, 26, 26, 0.08);
      height: 1px;
      width: 100%;
    }

    .menu-link {
      padding: 0 32px;
      color: $text;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      display: flex;

      &:hover {
        color: $brand;
      }

      &.secondary {
        font-weight: normal;
        color: $text-dark;

        &:hover {
          color: $brand;
        }
      }

      & .icon-container {
        display: inline-block;
        height: 24px;
        width: 24px;
        margin-right: 32px;
      }
    }
  }
}

.overlay {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: $background-lighter;
  z-index: 999;
}

.language-switch {
  display: flex;
  align-items: center;
  height: auto;
  position: relative;
  cursor: pointer;

  & p {
    margin-right: 16px;
  }
}

.language-switch-dropdown {
  display: flex;
  flex-direction: column;
  width: max-content;
  min-width: 140px;
  height: auto;
  padding: 32px;
  background-color: $background-white;
  position: absolute;
  top: 68px;
  right: 0;
  border-radius: 0 0 6px 6px;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.07);
  z-index: 20;

  & p {
    font-weight: 600;
    color: $text;
    margin-bottom: 32px;

    &:hover {
      color: $brand;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.active-text {
      color: $brand;
    }
  }
}

.language-loader {
  display: flex;
  align-items: center;
  opacity: 0.7;
}
