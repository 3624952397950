$label-weight: 400;
@import "~bulma/sass/form/_all.sass";

.input,
.textarea,
.select select {
  color: $text;
}

.alt-input {
  background-color: $background-lighter !important;
  color: #666666 !important;
  border-color: transparent !important;
  box-shadow: none !important;

  &[disabled] {
    background-color: $background-light !important;
    color: #8e8e8e;
  }
}

.label {
  font-size: 14px;
}

input.is-medium,
.is-medium > select {
  font-size: 16px !important;
  height: 48px;
  font-weight: 600;
}

.control.checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;

  label {
    padding-left: 35px;
  }

  label:hover {
    cursor: pointer;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid #979797;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &:hover input ~ label .checkmark {
    background-color: #f5f5f5;
  }

  input:checked ~ label .checkmark {
    background-color: $accent-primary;
    border: 1px solid $accent-primary;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ label .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  input:indeterminate ~ label .checkmark {
    background-color: $accent-primary;
    border: 1px solid $accent-primary;
  }

  input:indeterminate ~ label .checkmark:before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 16%;
    transform: translate(0, -50%);
    width: 70%;
    height: 2px;
    background: white;
  }
}

.control.checkbox-disabled {
  opacity: 0.5;
  pointer-events: none;
}

// this class is required for form field addons!
.button.is-static {
  border: 1px solid #dbdbdb;
  background-color: #f5f5f5;
  color: #7a7a7a;
  box-shadow: none;
  pointer-events: none;
  cursor: pointer;
  justify-content: center;
  padding: calc(0.5em - 1px) 1em;
  text-align: center;
  white-space: nowrap;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  border-radius: $radius;
}

.rjsf {
  .control {
    .help {
      margin-top: 0;
    }
  }
  .field:not(:last-child) {
    margin-bottom: 0;
  }
  .field.has-counter {
    margin-bottom: 16px;
  }
}

// react-select

.react-select-virtualized {
  height: 100%;
}

.fast-option {
  @extend .truncate;
  &.fast-option-focused {
    background-color: inherit;
    &:hover {
      background-color: $background-brand-light;
    }
  }

  &.fast-option-selected {
    background-color: inherit;
    color: $text-brand;
    &:hover {
      background-color: $background-brand-light;
    }
  }
}

// character counter form validation

.field {
  position: relative;
}

.field-flex {
  display: flex;
}

.character-counter {
  position: absolute;
  width: 100%;
  text-align: right;
  pointer-events: none;
  font-size: 0.75rem;
  left: 0;
  top: 100%;
}

// Addon button
.has-addons {
  .button.is-addon {
    border: 1px solid #dbdbdb;
    background-color: #209cee;
    color: $text-white;
    box-shadow: none;
    cursor: pointer;
    justify-content: center;
    padding: calc(0.5em - 1px) 1em;
    text-align: center;
    white-space: nowrap;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5em;
    border-radius: $radius;
    &.is-disabled {
      background-color: #f5f5f5;
      color: #7a7a7a;
      pointer-events: none;
    }
  }
}
