.notifications-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(0, 0, 0, 0.3); */
  //pointer-events: none;
  pointer-events: none;
  z-index: 999;
}

.notifications-sidebar {
  position: fixed;
  left: 50%;
  margin-left: -250px;
  top: 20px;
  width: 500px;
}

.notifications-container {
  animation-duration: 300ms;
  animation-name: slidein;
  min-width: 400px;
}
