#block-app-usage {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  #block-app-usage {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
  }
}
