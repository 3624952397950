.table {
  border-collapse: separate;
  border-spacing: 0;

  &.is-fullwidth {
    width: 100%;
  }

  &.wrap-overflow {
    span.text {
      overflow-wrap: anywhere;
    }
  }

  margin: 0;

  td,
  th {
    border-bottom-style: solid;
    border-bottom-color: $border-lighter;
    border-bottom-width: 1px;
    padding-right: 16px;
  }

  th {
    line-height: 56px;
    font-weight: 600;

    .header-checkbox {
      display: flex;
      position: relative;
      cursor: pointer;
      user-select: none;
      top: -10px;

      label {
        padding-left: 20px;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border: 1px solid #979797;
        box-sizing: border-box;
        border-radius: 8px;
      }

      &:hover input ~ label .checkmark {
        background-color: #f5f5f5;
      }

      input:checked ~ label .checkmark {
        background-color: $accent-primary;
        border: 1px solid $accent-primary;
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      input:checked ~ label .checkmark:after {
        display: block;
      }

      .checkmark:after {
        left: 6px;
        top: 2px;
        width: 7px;
        height: 13px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
  }

  td {
    line-height: 72px;

    &.is-active {
      background-color: $background-brand-light;
    }

    &.no-wrap {
      white-space: nowrap;
    }
  }

  td.sticky,
  th.sticky {
    position: sticky;
    left: 0;
    z-index: 13 !important;
    background: rgb(255, 255, 255);
  }

  td.sticky {
    z-index: 11;
  }

  tbody tr:last-child {
    > td {
      border: 0;
    }

    > th {
      border: 0;
    }
  }

  tbody th {
    line-height: 72px;
  }

  &.is-compact {
    td {
      line-height: 40px;
    }
  }

  &.no-wrap {
    th {
      white-space: nowrap;
    }

    td {
      white-space: nowrap;
    }
  }
}
