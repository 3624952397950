.custom-tooltip {
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;
  background: $background-white !important;
  color: $text-darker !important;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.08) !important;
  text-align: center !important;
  font-size: 14px !important;
  line-height: 1.2 !important;
  white-space: pre !important;
  opacity: 1!important;
}
