.illustration {
  //stroke: currentColor;

  &.is-32 > svg {
    max-width: unset;
    max-height: unset;
    width: 32px;
    height: 32px;
  }

  &.is-64 > svg {
    max-width: unset;
    max-height: unset;
    width: 64px;
    height: 64px;
  }
}

.illustration > svg {
  max-width: 100%;
  max-height: 100%;
}
