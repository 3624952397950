.tag {
  display: flex;
  align-items: center;

  background: initial;
  border: 1px solid transparent;
  border-radius: 100px;
  height: 24px;
  width: min-content;
  padding: 0 16px;

  .text {
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
  }

  &.is-green {
    background: $status-green-light;
  }
  &.is-orange {
    background: $status-orange-light;
  }
  &.is-red {
    background: $status-red-light;
  }
  &.is-purple {
    background: $status-purple-light;
  }
  &.is-blue {
    background: $brand-light;
  }
}
