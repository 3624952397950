.status-bar {
  height: 11px;
  position: relative;
  .status-background {
    background-color: $background-light;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .status-foreground {
    height: 100%;
    background-color: $brand;
    position: absolute;
  }
}
