@charset "utf-8";

// Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");

h1,
.heading-1 {
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 57px;
  color: $text;
}

h1.spaced {
  height: 116px;
}

h2,
.heading-2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: $text;
}

h2.spaced {
  height: 56px;
}

h3,
.heading-3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: $text;
}

h3.spaced {
  height: 40px;
}

h4,
.heading-4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: $text;
}

h4.spaced {
  height: 32px;
}

.text {
  font-size: 16px;
  display: inline-block;

  &.no-wrap {
    white-space: nowrap;
  }
}

.text b,
.text-emphasized {
  font-style: normal;
  font-weight: 600;
  color: $text;
}

.text.spaced {
  padding-bottom: 32px;
}

.text-small {
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: $text-darker;
}

.text-disabled {
  color: $text-light;
}

.text-small b,
.text-small.text-emphasized {
  font-style: normal;
  font-weight: 600;
  color: $text;
}

.text-small.text-disabled b,
.text-small.text-disabled.text-emphasized {
  color: $text-light;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
