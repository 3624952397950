// Base Button
button.button,
a.button {
  background: initial;
  border: 1px solid transparent;
  border-radius: 100px;

  white-space: nowrap;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &.processing {
    opacity: 0.8;
    pointer-events: none;
    &:hover {
      background: inherit !important;
    }
  }

  .text {
    font-weight: 600;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: 0 1px 12px rgba(73, 178, 197, 0.8) !important;
  }

  &:disabled {
    opacity: 0.5;
    cursor: initial;
    pointer-events: none;
  }

  // Sizes

  &.small {
    height: 32px;
    padding: 0 24px;
  }

  &.medium {
    height: 32px;
    padding: 0 32px;
    //min-width: 198px;
    &.has-icon {
      padding: 0 16px;
    }
  }

  &.big {
    height: 48px;
    padding: 0 48px;
    //min-width: 198px;
  }
  &.is-fullwidth {
    width: 100%;
  }

  // colors

  &.primary {
    background: $accent-primary;
    box-shadow: 0 1px 4px rgba(71, 117, 222, 0.4);

    .text {
      color: $text-white;
    }

    .icon {
      fill: $text-white;
    }

    &:hover {
      background: $accent-primary-hover;
    }
    &:focus {
      box-shadow: 0 1px 12px rgba(73, 178, 197, 0.8);
      background: $accent-primary;
    }
  }

  &.secondary {
    background: $accent-secondary;
    box-shadow: 0 1px 4px rgba(71, 117, 222, 0.4);

    .text {
      color: $text-brand;
    }

    .icon {
      fill: $text-brand !important;
    }

    &:hover {
      background: $accent-secondary-hover;
    }
  }

  &.tertiary {
    background: $accent-tertiary;
    border-color: $border-brand;

    .text {
      color: $text-brand;
    }

    .icon {
      fill: $text-brand !important;
    }

    &:hover {
      background: $accent-tertiary-hover;
    }
  }
}

// textbutton
a.text-button,
button.text-button {
  background: none;
  border: 1px solid transparent;
  height: 24px;
  padding: 0 0 4px;

  &.is-fullwidth {
    width: 100%;
  }

  > * {
    color: $text-brand;
    font-weight: 600;
  }

  .download-text {
    color: $text-brand;
    font-weight: normal;
    font-size: 1rem;
  }

  &:hover {
    cursor: pointer;
    border-bottom-color: $border-brand;
  }

  &:focus {
    outline: none;
  }

  &:disabled,
  &.disabled {
    border-bottom-color: #49b2c57a;
    & > span,
    & > div {
      opacity: 0.5;
    }
  }

  &:disabled {
    pointer-events: none;
    cursor: initial;
  }
}

.toggle-wrapper {
  display: flex;
  align-items: center;

  & .toggle-label {
    margin-right: 8px;
  }
}

.toggle-container {
  width: 70px;
  background-color: #f5f5f5;
  border: solid 1px #e2e2e2;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  padding: 2px;
  height: 32px;
  position: relative;

  & .toggle-button {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    cursor: pointer;
    background-color: #49b2c5;
    color: white;
    padding: 8px 12px;
    border-radius: 18px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    min-width: 32px;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    left: 34px;
    transition: all 0.3s ease;
    max-height: 26px;
    min-height: 26px;
  }

  & .toggle-disabled {
    background-color: #aaa;
    left: 2px;
  }
}

.disable-link {
  pointer-events: none;
}
