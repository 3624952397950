.detail-modal {
  display: flex;
  height: 99vh;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 0;
  width: 100%;
  overflow-x: auto;

  .close-icon {
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
    padding-top: 16px;
  }
  .content {
    padding-right: 16px;
    padding-left: 16px;
    max-height: 90vh;
  }
}
