.file-drop {
  min-height: 256px;
  border: 4px dashed $border-light;
  background: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;

  .dragging {
    opacity: 0.2;
  }

  .drag-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
  }
}

.file-drop.read-only {
  .file-label {
    cursor: default;
  }

  &:hover {
    cursor: default;
  }
}
