.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 998;

  .modal-wrapper {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 64px 0;
  }

  .modal-wrapper > * {
    max-height: 100%;
  }

  .modal-background {
    background: $background-modal-dark;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

  }

  .modal-card {
    // TODO: Use box
    margin: 0;
    padding: 0;
    background: $background-white;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    flex-grow: 1;

    position: relative;
    //top: 30%;
    width: inherit;
    z-index: 10001;

    .modal-card-head {
      .modal-card-title {
        border-bottom: $border-lighter;
        border-bottom-style: solid;
        margin-top: 16px;
        padding-left: 32px;
        padding-bottom: 16px;
      }
      .delete {
        -moz-appearance: none;
        -webkit-appearance: none;
        border: none;
        cursor: pointer;
        display: inline-block;
        flex-grow: 0;
        flex-shrink: 0;
        //font-size: 0; //TODO: re-add after we have icons
        outline: none;
        vertical-align: top;
        height: 5px;
        width: 20px;
        background: inherit;

        position: absolute;
        right: 23px;
        top: 23px;
      }
    }
    .modal-card-body {
      margin-top: 64px;
      padding-bottom: 64px;

      .modal-card-buttons {
        position: relative;
        padding-top: 58px;
      }
    }
  }
}
