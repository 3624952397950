html, body {
  background: $background-lighter;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: $text-darker;
  position: relative;
}

a {
  text-decoration: none;
}

input[disabled] + label {
  .checkmark, .checkmark:hover {
    background: #c5c5c5;
  }
}
