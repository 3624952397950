.pagination {
  align-items: center;
  display: flex;
  text-align: center;

  .pagination-link {
    //TODO: @extend button.button.tertiary;
    border: 1px solid $border-brand;
    background: $accent-tertiary;
    border-radius: 100px;
    display: inline-block;

    height: 32px;
    min-width: 32px;
    margin-right: 8px;

    .text {
      color: $text-brand;
      font-weight: 600;
    }

    &:hover {
      background: $accent-tertiary-hover;
      cursor: pointer;
    }

    &.is-current {
      //TODO: @extend button.button.primary;
      background: $accent-primary;
      box-shadow: 0 1px 4px rgba(71, 117, 222, 0.4);

      .text {
        color: $text-white;
      }

      &:hover {
        background: $accent-primary-hover;
      }
    }
  }

  .pagination-ellipsis {
    margin-right: 8px;
    color: $border-light;
  }

  .pagination-list {
    display: flex;
  }

  .pagination-buttons {
    margin-left: auto;
  }

  button.pagination-previous,
  button.pagination-next {
  }

  button.pagination-previous {
    margin-right: 8px;
  }
}
