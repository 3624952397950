.icon {
  fill: currentColor;
  display: flex;

  &.clickable {
    cursor: pointer;
  }

  &.clickable:hover {
    fill: $brand;
  }

  &.is-48 > svg {
    max-width: unset;
    max-height: unset;
    width: 48px;
    height: 48px;
  }
  &.is-24 > svg {
    max-width: unset;
    max-height: unset;
    width: 24px;
    height: 24px;
  }
  &.is-32 > svg {
    max-width: unset;
    max-height: unset;
    width: 32px;
    height: 32px;
  }

  &.is-16 > svg {
    max-width: unset;
    max-height: unset;
    width: 16px;
    height: 16px;
  }

  &.is-12 > svg {
    max-width: unset;
    max-height: unset;
    width: 12px;
    height: 12px;
  }

  &.spinner > svg {
    animation: spin 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  &.is-centered > svg {
    flex: auto;
  }
}

.icon > svg {
  max-width: 100%;
  width: 24px;
  height: 24px;
  max-height: 100%;
}
