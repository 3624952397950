.loading-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
}
