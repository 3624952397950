@charset "utf-8";

@import "base/variables.scss";

$primary: $brand;
$link: $brand;
$font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$family-primary: $font-family;
$family-sans-serif: $font-family;
// set default radius to 6
$radius: 6px;

input,
select {
  font-family: $font-family;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

// bulma core
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/minireset.sass";
@import "~bulma/sass/helpers/_all";
@import "~bulma/sass/elements/container.sass";
@import "~bulma/sass/elements/content.sass";

//@import "~bulma/sass/base/_all.sass";

//@import "~bulma/sass/elements/button.sass";
//@import "~bulma/sass/elements/content.sass";
//@import "~bulma/sass/elements/tag.sass";

@import "layout/forms.scss";
@import "layout/columns.scss";
@import "base/typography.scss";
@import "components/buttons.scss";
@import "layout/box.scss";
@import "layout/stack.scss";
@import "layout/navbar.scss";
@import "layout/modal.scss";
@import "components/pagination";
@import "components/notifications";
@import "components/icons";
@import "layout/table";
@import "layout/steps";
@import "components/timeline";
@import "components/tag";
@import "components/badges";
@import "components/upload";
@import "components/textarea";
@import "components/illustration";
@import "layout/lists";
@import "layout/breadcrumbs";
@import "layout/tabs";
@import "layout/blocker";
@import "components/statusbar";
@import "components/loading";
@import "pages/duplicate-matching";
@import "components/shimmer-loader";
@import "components/tooltip";

@import "../tooltip";
@import "pages/matching";
@import "base/defaults";
@import "base/ghost-user";

@supports (content-visibility: auto) {
  .html {
    content-visibility: auto;
  }
}
