/* ----------------------------- Tooltip ----------------------------- */

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  cursor: pointer;
}

[data-tooltip]:focus {
  outline: none;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  //   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  //   filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
  outline: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  left: 50%;
  transform: translate3D(-50%, 0, 0);
  padding: 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: $background-white;
  color: $text-darker;
  content: attr(data-tooltip);

  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  white-space: pre;
}

[data-tooltip]:not([data-direction]):before {
  bottom: 100%;
  margin-bottom: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.08);
}

[data-tooltip][data-direction="bottom"]:before{
  top:100%;
  margin-top: 15px;
  box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.04), 0 -2px 16px rgba(0, 0, 0, 0.08);
}


/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  display: block;
  left: 50%;
  margin-left: -9px;
  width: 0;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

[data-tooltip]:not([data-direction]):after {
  bottom: 100%;
  margin-bottom: 8px;
  border-top: 9px solid #fff;
}



[data-tooltip][data-direction="bottom"]:after{
  top: 100%;
  margin-top: 8px;
  border-bottom: 9px solid #fff;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  //   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  //   filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  z-index: 999;
}
