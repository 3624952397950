@use "sass:math";
@import "~bulma/sass/grid/columns.sass";
@import "~bulma/sass/grid/tiles.sass";

.columns {
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0 !important;

  .column {
    padding-top: 0;
    padding-bottom: 0;
    max-width: 100%;
  }
  @for $i from 2 through 11 {
    &.has-#{$i}-columns {
      @for $j from 0 through 12 {
        & > .column.is-#{$j} {
          width: percentage(math.div($j, $i));
        }
        & > .column.is-offset-#{$j} {
          margin-left: percentage(math.div($j, $i));
        }
      }
    }
  }
}
