.breadcrumb {
  white-space: nowrap;

  a {
    //align-items: center;
    //display: flex;
    //justify-content: center;
    padding: 0 0.75em;
    text-decoration: none;
    color: $text-brand;
  }
  a:hover {
    text-decoration: underline;
  }
}
