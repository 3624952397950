.textarea {
  background: $background-white;
  border-radius: 8px;

  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: $text;

  &:hover {
    cursor: pointer;
    //background: $background-brand-light;
  }

  &:focus {
    //border-color: #49b2c5;
  }

  &:disabled {
    background: $background-light;
    cursor: initial;
    pointer-events: none;
  }

  &.no-resize {
    resize: none;
  }
}
