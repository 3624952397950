.tabs {
  .tab {
    color: $text-darker;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 8px;

    &.is-selected {
      color: $text-brand;
      text-decoration: none;
      border-bottom: 4px $text-brand solid;
      border-radius: 0;
    }
  }
}

.side-menu {
  .menu-item {
    color: $text-darker;
    justify-content: unset;
    text-align: left;

    &.is-selected {
      color: $text-brand;
      text-decoration: none;
    }
  }
  text-align: left;
}
