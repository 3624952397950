@keyframes placeHolderShimmer {
  0% {
    background-position: -454px 0;
  }
  100% {
    background-position: 454px 0;
  }
}

.shimmer {
  animation-duration: 1.9s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #e9e8eb;
  background: linear-gradient(to right, #e9e8eb 2%, #f5f5f5 22%, #e9e8eb 64%);
  background-size: 908px;
  position: relative;
}
