:root {
  --text-color: #232323;
  --brand-color: #49b2c5;
  --hint-color: #979797;
  --dark-brand-color: #2c6b76;
}

.support-modal {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 100;
}

.support-card {
  background: #fff;
  display: inline-block;
  width: 450px;
  height: auto;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.support-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  border-bottom: solid 1px #ddd;
  padding: 24px;
}

.support-head h2 {
  font-size: 24px;
  font-weight: 600px;
}

.support-body {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
}

.support-email {
  width: 85%;
  height: auto;
  background: #f5f5f5;
  padding: 16px 24px;
  position: relative;
}

.support-email p {
  width: 70%;
  font-size: 14px;
  color: var(--text-color);
  margin-bottom: 8px;
}

.support-email .button {
  padding: 0 16px !important;
  margin-right: 0;
}

.support-illustration {
  position: absolute;
  right: -40px;
  bottom: -30px;

  & .illustration {
    width: 160px;
    height: 160px;
  }
}

.support-documentation {
  padding: 24px 24px 8px 24px;
}

.support-phone {
  padding: 24px 24px 16px 24px;
}
.support-time {
  padding: 8px 24px 16px 24px;
}

.support-documentation p,
.support-phone span,
.support-phone p,
.support-time p {
  font-size: 16px;
  color: var(--text-color);
}

.support-documentation a,
.support-phone a {
  font-size: 14px;
  font-weight: 600;
  color: var(--brand-color);
}

.support-time h3 {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 8px;
}

.support-hint,
.support-hint span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.support-hint span {
  color: var(--hint-color);
  font-size: 12px;
  text-transform: uppercase;
}

.support-hint * p:first-child {
  padding: 0 16px;
}

.support-extra {
  width: 65%;
  margin-top: 16px;
  font-size: 14px !important;
}

.support-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  padding: 8px;
  background: var(--brand-color);
  border: none;
  cursor: pointer;
  box-shadow: 0 1px 4px rgba(71, 117, 222, 0.4);
}

.support-button:hover {
  background: var(--dark-brand-color);
}
