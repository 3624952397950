.stack.space-8 > .stack-item {
  margin-top: 8px;
}
.stack.space-16 > .stack-item {
  margin-top: 16px;
}
.stack.space-24 > .stack-item {
  margin-top: 24px;
}
.stack.space-32 > .stack-item {
  margin-top: 32px;
}
.stack.space-40 > .stack-item {
  margin-top: 40px;
}
.stack.space-48 > .stack-item {
  margin-top: 48px;
}
.stack.space-56 > .stack-item {
  margin-top: 56px;
}
.stack.space-64 > .stack-item {
  margin-top: 64px;
}

.stack {
  .stack-item {
    //display:flex;
    &:first-child {
      margin-top: initial;
    }
    &:empty {
      margin-top: 0 !important;
    }
    a {
      &:active,
      &:focus {
        outline-color: $border-brand;
      }
    }
  }

  &.centered {
    .stack-item {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
}

.h-stack.space-8 > .stack-item {
  margin-right: 8px;
}
.h-stack.space-16 > .stack-item {
  margin-right: 16px;
}
.h-stack.space-24 > .stack-item {
  margin-right: 24px;
}
.h-stack.space-32 > .stack-item {
  margin-right: 32px;
}
.h-stack.space-40 > .stack-item {
  margin-right: 40px;
}
.h-stack.space-48 > .stack-item {
  margin-right: 48px;
}
.h-stack.space-56 > .stack-item {
  margin-right: 56px;
}
.h-stack.space-64 > .stack-item {
  margin-right: 64px;
}

.h-stack {
  display: flex;
  .stack-item {
    &:last-child {
      margin-right: 0;
    }
    &:empty {
      margin-right: 0;
    }
  }

  &.centered {
    .stack-item {
      display: flex;
      align-items: center;
    }
  }

  &.is-fullheight {
    height: 100%;
    .stack-item {
      height: 100%;
    }
  }
}
